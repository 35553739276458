<template>
  <div>

    <!--/ search input -->

    <div id="knowledge-base-category">
      <b-row class="match-height">
        <b-col
          v-for="(data,index) in filteredKB"
          :key="index"
          md="4"
          sm="6"
        >
          <b-card>
            <h6 class="kb-title">
              <feather-icon
                :icon="data.icon"
                size="20"
                class="mr-50"
                :class="data.iconColor"
              />{{ data.title }} ({{ data.questions.length }})
            </h6>
            <b-list-group class="list-group-circle mt-2">
              <b-list-group-item
                v-for="(que) in data.questions"
                :key="que.question"
                class="text-body"
                :slug="que.slug"
               >
               <administrator-select
                :type="que.type"
                :href-name="que.question"
                :href-value="que.href"
                :method-name="que.methodName"
               ></administrator-select>

              </b-list-group-item>
            </b-list-group>
          </b-card>
        </b-col>

        <!-- no result found -->
        <b-col
          v-show="!filteredKB.length"
          cols="12"
          class="text-center"
        >
          <h4 class="mt-4">
            Search result not found!!
          </h4>
        </b-col>
        <!--/ no result found -->
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BCardBody, BCardText, BForm, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import ImportCompanyType from './ImportCompanyType.vue'
import AdministratorSelect from '@/views/administrator/AdministartorSelect'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    ImportCompanyType,
    AdministratorSelect,
  },
  data() {
    return {
      kb: [],
      category:'',
    }
  },
  computed: {
    filteredKB() {
      return this.kb.filter(item => item.category.toLowerCase().includes(this.category))
    },
  },
  created() {
    // ! You have to update the below API call according to route parameter
    // * We are using fixed API call for all categories for demo purposes
    this.category = this.$route.params.category
    this.$http.get('/administrator/export/category').then(res => {this.kb = res.data})
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
</style>
