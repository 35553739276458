<template>
  <div>
      <b-list-group-item v-if="type==='download'" >
          <a  :href=hrefValue> {{hrefName}} </a>
      </b-list-group-item>

      <b-list-group-item v-if="type==='import'"   @click="modalShow">
           {{hrefName}}
      </b-list-group-item>


      <b-modal
              id="modal-stock-log"
              centered
              size="xl"
              title="导入数据"
              hide-footer="true"
              ref="importCompany"
      >
          <b-row>
              <b-col md="12">
                  <b-form-group
                          label-cols="1"
                          label-cols-lg="1"
                          label-for="attachments_ids"
                          label="相关附件"
                          label-size="sm"
                          class="mb-1"
                  >
                      <attachment-upload :theme="'files'"
                                         :object_type="'excel'"
                                         @change="onUploadedExcel"
                      />
                  </b-form-group>
              </b-col>
              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                  <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          @click="exportExcel"
                  >
                      <span class="align-right">导入</span>
                  </b-button>

              </b-col>
          </b-row>
      </b-modal>
  </div>

</template>

<script>

import XyGroup from '@/views/components/xy/XyGroup'
import flatPickr from 'vue-flatpickr-component'
import {BInputGroup, BFormInput, BInputGroupAppend, BInputGroupPrepend, BRow, BCol, BButton,VBModal} from 'bootstrap-vue'
import {onMounted, onUnmounted, reactive, toRefs} from "@vue/composition-api";
import store from "@/store";
import vSelect from 'vue-select'
import CompanySelect from "@/views/apps/company/CompanySelect"

import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import StoreList from "@/views/apps/store/store-modal/StoreListSelect";
import StoreWarehouseList from "@/views/apps/store/store-warehouse/StoreList";
import WarehouseList from "@/views/apps/warehouse/warehouse-modal/WarehouseList";
import ProductSelect from '@/views/apps/product/product-salesorder/ProductSelect'
import modal from "@/views/components/modal/Modal";
import BrandSelect from "@/views/apps/brand/BrandSelect";
import BrandRewardSelect from "@/views/apps/brandreward/BrandRewardSelect";
import {useToast} from "vue-toastification/composition";
import companyStore from "@/views/apps/company/companyStore";
import {avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions, toDate, toTime} from "@core/utils/filter";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";

export default {
  components: {
    XyGroup,
    flatPickr,
    BInputGroup,
    VBModal,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BButton,
    vSelect,
    CompanySelect,
    UserSelect,
    StoreList,
    WarehouseList,
    ProductSelect,
    StoreWarehouseList,
    BrandSelect,
    BrandRewardSelect,
      AttachmentUpload
  },
  data(){
    return{
      data:{},
      excelSqlId:0,
    }
  },
  props: {
    // 下载链接
    hrefValue:{
      type: String,
      default: ''
    },
    // 链接名字
    hrefName:{
          type: String,
          default: ''
    },
      //
    type:{
        type: String,
        default: ''
    },
    // 方法名
    methodName:{
        type: String,
        default: ''
    }

  },
  methods:{
  },
  setup(props) {
        const toast = useToast()
        // Register module
        if (!store.hasModule('company')) store.registerModule('company', companyStore)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule('company')) store.unregisterModule('company')
        })



        const onUploadedExcel = function (id, attachment, result) {
            this.excelSqlId = result
        }

        const modalShow = function () {
            this.$refs[props.methodName].show()
        }

        const exportExcel = function () {

            const params = {
                excelSqlId: this.excelSqlId,

            }
            store.dispatch('company/exportExcel', params)
                .then(response => {
                    const data = response.data.data
                    if (response.data.code == 0) {
                        toast.success("导入成功")
                        refetchData();
                    } else {
                        toast.error(response.data.data)
                    }
                })
        }



        return {
            onUploadedExcel,
            exportExcel,
            modalShow

        }
    },
}
</script>

<style scoped>

</style>
